  .collapsiblea {
    background-color: #eee;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    border: none;
    text-align: left;
    outline: none;
    font-size: 15px;
  }
  .active, .collapsiblea:hover {
    background-color: #ccc;
  }
  
  /* Style the collapsible content. Note: hidden by default */
  .contentcol {
    padding: 0 18px;
    display: none;
    overflow: hidden;
    background-color: #000000;
  }

 .tagline1 {
    font-size: 20px;
    font-weight: bold;
    color: #fc7b1f;
    text-align: center;
    margin-top: 20px;
  }

  .exchangesbtn{
    background-color: #fc7b1f;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  
  .divider1{
    width: 100%;
    height: 1px;
    background-color: #eee;
    margin-top: 20px;
  }
  .mainpad{
    padding: 20px;
  }

  .mainmenu1{
    display: flex;
    margin: 0 0 0 0 !important;
    color: #fc7b1f;
    font-size: 2rem;
  }

  .mailchimp{
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }

  .mailchimp button {
    background-color: #fc7b1f;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
  }
  .small {
    font-size: 8px !important;
  }
  .emailcta{
    color: #fc7b1f;
  }

  .mt-main{
    margin-top: 20px;
  }

  .containerwbg{
    padding: 20px;
    border-radius: 1vw;
  }

  @media screen and (max-width: 600px) {
    .mainmenu1{
      display: flex;
      margin: 0 0 0 0 !important;
      color: #fc7b1f;
      font-size: 1.5rem;
    }
  
    
  }

  .container-all{
    width: auto;
   
    
  }
  .container3{
    width: calc(33% - 6px);
    overflow:hidden;
    height: fit-content;
    margin:3px;
    padding: 0;
    display:block;
    position:relative;
    float:left;
  }
  .container3 img{
    margin: auto;
    width: auto;
    transition-duration: .3s;
    max-width: 100%;
    display:block;
    overflow:hidden;
    cursor:pointer;
    height: 9vw;
  }
  .container4 img{
    width: 13vw;
    transition-duration: .3s;
    max-width: 100%;
    display:block;
    overflow:hidden;
    cursor:pointer;
    height: 12vw;
  }

  .container3:hover img{
    transform: scale(1.2);
    transition-duration: .3s;
    filter: grayscale(50%);
    opacity: .7;
  }
  .container3:hover span{
    color:white;
    display: block;
    transition-duration: .3s;
  }
  @media only screen and (max-width: 900px) {
      .container {
          width: calc(50% - 6px);
      }
  }
  @media only screen and (max-width: 400px) {
      .container {
          width: 100%;
      }
  }
